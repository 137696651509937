.featuredSocialNetworkWrapper,
.header,
.section {
    display: flex;
}

.header {
    margin-top: 10px;
    align-items: center;
    gap: 8px;
}


.section {
    flex-direction: column;
}

.featuredSocialNetworkWrapper {
    margin-bottom: 3rem;
    align-items: flex-start;
    flex-direction: column;
}

.featured {
    margin: 0;
    font-size: 2.5rem!important;
}
