.osWrapper{
	scroll-behavior: smooth;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    background-color: #080D4D;
	padding: 0;
}

/* Largura da barra de rolagem */
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #C0C0C0 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .osWrapper::-webkit-scrollbar {
    width: 14px;
  }

  .osWrapper::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .osWrapper::-webkit-scrollbar-thumb {
    background-color: #C0C0C0!important;
    border-radius: 20px;
    border: 3px solid #ffffff;
  }
  

.wrapper {
	opacity: 1 !important;
    background-color: transparent !important;
    background-image: none !important;
    overflow: visible !important;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    height: 100% !important;
    width: 100% !important;
    transition: all 200ms ease-out 0s !important;
}

.previewEditing {
	scroll-behavior: smooth;
	opacity: 1 !important;
    background-color: transparent !important;
    background-image: none !important;
    overflow: visible !important;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    height: 100% !important;
    width: calc(100% - 400px) !important;
    transition: all 200ms ease-out 0s !important;
}


.editLandingPage {
	position: fixed;
	z-index: 1000;
	bottom: 40px;
    left: 0;
	background-color: #07024D;
}

.editLandingPage:hover{
	background-color: #07024Dcc;
}

.editingLandingPage {
	position: fixed;
	z-index: 1000;
	bottom: 40px;
	left: 400px!important
}

.backButton {
	position: fixed;
	z-index: 1000;
	top: 40px!important;
	right: 4px;
	background-color: #07024D;
}

.backButton:hover{
	background-color: #07024Dcc;
}

.desktop {
	width: 100%;
	/* transition: width 1s; */
	transition: opacity 0.2s ease 0s, width 0.3s ease-in-out 0s, min-width 0.3s ease-in-out 0s, filter 0.2s ease 0s;
    opacity: 1;
}

.tablet {
	width: 100%;
	max-width: 1024px;
}

.mobile {
	width: 428px; /* Largura inicial */
	max-width: 767px;
}

/* Adicionando a propriedade de transição */
.desktop,
.tablet,
.mobile {
	transition: max-width 1s ease; /* Animando a mudança de max-width */
}


@keyframes showMenutrue {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.showMenutrue {
    display: flex;
    animation: showMenutrue 1s ease 0s 1 normal forwards;
}


@keyframes showMenufalse {
	0% {
		opacity: 1;
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		transform: translateX(-50px);
	}
}

.showMenufalse {
    height: 0;
    overflow: hidden;
    animation: showMenufalse 1s ease 0s 1 normal forwards;
}
