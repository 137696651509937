.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 4rem;
}

.imgAlbum {
    position: relative;
    left: 0;
    top: 0;
    margin: auto;
}

.img {
    width: 100%;
    max-width: 400px;
    border-radius: 12px;
}

.buttonGroup {
    transition: all .3s ease-in-out;
    font-weight: bold;
}

.buttonGroup:hover {
    filter: brightness(0.9);
}

.tooltip {
    width: initial!important
}

@media screen and (max-width: 1200px) {
    .grid {
        gap: .725rem;
        grid-template-columns: 1fr 1fr;
    }

    .img {
        max-width: 300px;
    }
}

@media screen and (max-width: 768px) {
    .grid {
        gap: .725rem;
        grid-template-columns: 1fr;
    }
}
