.fileWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-self: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    height: 100%;
    min-height: 40px;
    border-radius: 1rem;
    border: solid 1px;
}

.titleSection {
    margin-bottom: 0.625rem;
}

.btn {
    display: flex;
    width: 100%;
}

.iconWrapper {
    flex: 1;
    padding: .5rem 1rem;
    border-radius: 15px 0 0 15px;
    display: flex;
    justify-content: center;
    min-height: 40px;
}

.icon {
    align-self: center;
    font-size: 16px!important;
}

.title {
    flex: 10;
    text-align: center;
    margin: 0 auto;
    padding: .5rem 1rem;
    border-radius: 0 15px 15px 0;
    white-space: nowrap;      
    overflow: hidden;          
    text-overflow: ellipsis;
    font-size: 16px!important;
    font-weight: bold;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}