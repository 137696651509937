.selectedSpotify {
    border-color: #1DB954!important;
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.selectedYoutube {
    border-color: #FF0000!important;
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.selectedSocialNumbers {

}

.baseButton {
    background-color: #0C09DB;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 50px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.baseButton:hover {
    background-color: navy;
}

.baseButton:focus {
    outline: none;
}

.baseButton:active {
    transform: translateY(2px);
}

.finishButton {
    background-color: green;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 50px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.finishButton:hover {
    background-color: darkgreen;
}

.finishButton:focus {
    outline: none;
}

.finishButton:active {
    transform: translateY(2px);
}
