a.filepond--credits {
    display: none!important;
}

.filepond-button {
    /* background-color: #f1f0ef; 
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    width: 100%!important;
    max-width: 170px; */
    position: absolute;
    right: 10px;
    z-index: 1000;
}

.filepond-button:hover,
.filepond--drop-label:hover {
    cursor: pointer;
}

.filepond--root {
    margin-bottom: 0!important;
    width: 100%;
}