.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.news {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}

.imageArticle {
    aspect-ratio: 16 / 9;
    object-fit: cover!important;
    max-width: 300px;
    border-radius: .75rem;
}

.titleArticle,
.imageArticle {
    flex: 1
}

.titleArticle {
    max-width: 400px;
    line-height: 26px;
}

@media screen and (max-width: 768px){
    .wrapper {
        flex-direction: column;
        align-items: center;
    }

    .imageArticle {
        max-width: 100%;
    }
}