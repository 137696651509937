/* COLORS */
.colorPrimary {
    background-color: #2176FF;
    color: #FFF;
    transition: 300ms all;
    border: none;
}

.colorPrimary:hover {
    background-color: #1753B3;
    color: #FFF;
}

.disabledCustom {
    background-color: #e9ecef !important;
}

.linkPrimary {
    color: #2176FF;
    font-weight: bold;
}

.filepond-upload {
    width: 100%;
}

.colorSuccess {
    background-color: #10c469;
    color: #FFF;
    transition: 300ms all;
}

.colorSuccess:hover {
    background-color: #23782B;
    color: #FFF;
}

.colorSecondary {
    background-color: #2f4858;
    color: #FFF;
}

.colorSecondary:hover {
    background-color: #243845;
    color: #FFF;
}

.colorDanger {
    background-color: #D00000;
    color: #FFF;
}

.colorDanger:hover {
    color: #FFF;
}

.colorAlert {
    color: #FFA500;
}

.bgColorAlert {
    background-color: #FFA500;
}

.validationErrors {
    color: #D00000 !important;
}

.input-error {
    border: solid 1px #D00000;
}

p.errorMessage {
    color: #D00000;
    font-size: 12px;
}

/* TEXT STYLES */
.strongStyle {
    font-size: 1rem;
    color: #353535;
}

.colorContent {
    color: #5d737e;
}


/*PAID CAMPAIGNS SOCIAL ICONS */
.facebookIconStyle {
    font-size: 1.2rem;
    color: #3b5998;
}

.youtubeIconStyle {
    font-size: 1.2rem;
    color: #C4302B;
}

.infoIconStyle {
    font-size: 1.2rem;
}


/* FORM STYLES */
.labelStyles {
    font-size: 1rem;
    color: #353535;
}


/* BREADCRUMBS */
.bg-breadcrumb {
    background-color: #f6f6f6;
}

.breadcrumbLink {
    color: #1753B3;
}

.breadcrumbLink:hover {

}

.breadcrumbLastLink {
    color: #7f7f7f;
}

/* GENERAL COMPONENT STYLES */
.loadingCard {
    height: calc(100vh - 154px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dataTableCard {
    padding: 1.5rem;
}

.table-responsive {
    overflow-x: inherit;
}

/* NAV MENU */
.nav-menu {
    z-index: 1000;
    top: 70px;
    right: 10px;
    left: 253px;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
}

@media (max-width: 1029px) {
    .nav-menu {
        left: 252px;
    }
}

@media (max-width: 767px) {
    .nav-menu {
        display: flex;
        left: 10px;
    }

    h2 {
        font-size: 1.5rem;
        margin: 20px 0;
    }
}

@media (max-width: 575.98px) {
    .nav-menu {
        flex-direction: column;
    }

    .container-fixed {
        margin-bottom: 40px;
    }
}

.nav-menu li a {
    color: #343a40;
}

.nav-menu i {
    color: #1753b3;
}

.col-left {
    max-width: 200px;
}

.col-right {
    width: auto;
    padding-right: 8rem;
}

.register_artist {
    cursor: pointer;
    color: #2176FF !important;
}

.filepond--drop-label {
    padding: 2px;
}

.filepond--label-action {
    text-decoration-color: #2176FF !important;
    font-weight: bold;
}

.filepond--root {
    font-size: 12px !important;
}

.danger-zone {
    border-color: #FF6A69;
    border: solid 2px #FF6A69 !important;
}

.danger-zone-text {
    color: #FF6A69;
}

.w100 {
    width: 200px;
}

.form-check {
    padding-bottom: 1rem !important;
}

.form-check-input {
    margin-top: 0 !important;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2196F3;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
