/* arquivo css para classes isoladas */

html {
    scroll-behavior: smooth;
}

.wrapper {
    position: relative;
    /* overflow-y: scroll; */
}

/* .wrapper img {
    object-fit: cover;
    max-width: 300px;
    border-radius: .75rem
} */

.bioContent {
    display: flex;
    gap: 2rem;
}

.bioText {
    flex: 1;
}

.bioImg {
    flex: 1;
    max-height: 100%;
    border-radius: .75rem;
    border: solid 10px var(--red);
    box-shadow: 0 0 0 10px var(--yellow);
}

@media screen and (max-width: 1024px) {
    .bio {
        flex-direction: column;
        gap: 4rem
    }
}

@media screen and (max-width: 768px) {
    .bio {
        flex-direction: column;
    }

    .bioImg {
        display: none;
    }
}
