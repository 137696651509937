/* .carousel-control-next-icon {
    filter: none!important;
}

.carousel-control-prev-icon {
    background-color: red!important;
} */

/* Estilizando a seta anterior */
.light .carousel-control-prev-icon {
  filter: none!important;
  background-color: #ff0000!important; /* Altere para a cor desejada */
  color: #ffffff!important; /* Cor do ícone da seta */
}

.dark  .carousel-control-prev-icon {
  filter: none!important;
  /* background-color: #ffffff!important; Altere para a cor desejada */
  color: #0095E8!important; /* Cor do ícone da seta */
}
  
  /* Estilizando a seta seguinte */
  .carousel-control-next-icon {
    background-color: #00ff00!important; /* Altere para a cor desejada */
    color: #ffffff!important; /* Cor do ícone da seta */
}

  /* Estilizando os indicadores de slide */
.dark .carousel-indicators button {
    background-color: #f6f6f6!important; /* Altere para a cor desejada */
}
  
  /* Estilizando o indicador ativo (slide atual) */
  .carousel-indicators .active {
    background-color: #0095E8!important; /* Altere para a cor desejada */
}
  