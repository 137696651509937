.wrapper {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}

.imageTour {
    aspect-ratio: 1 / 1;
    object-fit: cover!important;
    max-width: 400px;
    border-radius: .75rem;
}

.videoTour {
    max-width: 50%;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
}

.textTour,
.imageTour,
.videoTour {
    flex: 1
}

.textTour {
    /* max-width: 400px; */
    line-height: 26px;
}

@media screen and (max-width: 768px){
    .wrapper {
        flex-direction: column;
        align-items: stretch;
    }

    .imageTour {
        max-width: 100%;
    }

    .videoTour {
        max-width: 100%;
    }
}