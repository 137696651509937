.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height:auto;
}

.swiper-pagination-bullets {
  bottom: 20px!important;
}

.swiper-button-next {
  background-image: url("./iconRight.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 35%
}

.swiper-button-next::after {  
  display: none;
}

.swiper-button-prev {
  background-image: url("./IconLeft.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 35%
}

.swiper-button-prev::after {
  display: none;
}

/* :root {
  --swiper-navigation-size: 44px;
} */