.introjs-dontShowAgain label {
    background-color: transparent!important;
}

.introjs-skipbutton {
    color: #F1416C;
}

.introjs-skipbutton:hover {
    color: #d9214e;
}

.introjs-arrow.top {
    border-bottom-color: transparent!important;
}