.middle-column {
    gap: 16px;
}

.wrapper {
    display: grid;
    grid-template-areas:
        "TD HC RL"
        "TD SF CS"
        "TD SF OS"
    ;
    grid-template-rows: 1fr auto 145px;
    grid-template-columns: 300px minmax(200px, auto) 200px;
    width: 100%;
    gap: 1rem;
}

.wrapper.withoutCurrentArtist {
    grid-template-areas:
        "TD HC RL"
        "TD CS RL"
        "TD CS OS"
    ;
}

.td {
    grid-area: TD;
    width: 100%;
    height: 80%;
}

.hc {
    grid-area: HC;
}

.sf {
    grid-area: SF;
    height: 100%;
}

.rl {
    grid-area: RL;
}

.cs {
    grid-area: CS;
}

.os {
    grid-area: OS;
}

@media screen and (max-width: 1024px) {
    .wrapper {
        grid-template-areas:
            "TD HC"
            "TD SF"
            "RL CS"
            "OS OS"
        ;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 300px minmax(200px, auto);
        width: 100%;
        gap: 1rem;
    }

    .wrapper.withoutCurrentArtist {
        grid-template-areas:
            "TD HC"
            "TD CS"
            "RL OS"
        ;
    }

    .td,
    .sf {
        height: 100%;
    }

    .rl > div {
        width: 100%!important;
        height: 100%!important;
    }
}

@media screen and (max-width: 768px ){
    .wrapper {
        grid-template-areas:
            "TD"
            "HC"
            "SF"
            "RL"
            "CS"
            "OS"
        ;
        grid-template-rows: 1fr;
        grid-template-columns: 100%;
        width: 100%;
        gap: 1rem;
    }

    .wrapper.withoutCurrentArtist {
        grid-template-areas:
            "TD"
            "HC"
            "CS"
            "RL"
            "OS"
        ;
    }
}

/* .col-1,
.col-2,
.col-3 {
    width: 100%;
}

.col-1 {
    max-width: 300px;
    flex: 1;
}

.col-2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    flex: 2;
    flex-grow: 1;
}

.col-3 {
    max-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
} */

/* @media screen and (max-width: 728px) {
    .wrapper {
        flex-direction: column;
    }

    .col-1,
    .col-2,
    .col-3 {
        max-width: 100%
    }
} */



/* @media screen and (min-width: 1201px){
    .middle-column {
        gap: 32px
    }
} */