.gridVideos {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
    grid-gap: 1.5rem;
}

.gridVideos > .video {
    width: 100%;
    height: auto; 
}

.videoNcta {
    display: flex;
    flex-direction: column;
    align-items:center;
    gap:.5rem;
}

.cta {
    font-size: 1rem!important;
    font-weight: bold!important;
}

@media screen and (max-width: 1024px) {
    .gridVideos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .gridVideos {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}