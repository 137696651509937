.wrapper {
    display: flex;
}

.flex1 {
    flex: 1 1;
}

.featuredImage {
    border-radius: 12px;
    aspect-ratio: 1 / 1;
    max-width: 580px;
    max-height: 580px;
    margin: 0;
}

.latestRelease {
    position: relative;
}

.spotifyIframe {
    position: absolute;
    left: -100px;
    border-radius: 14px;
    margin-top: 1rem;
    width: 100%;
    height:500px;
}

.wrapperTitle {
    margin: 40px 0 60px 80px;
}

.albumDescription {
    margin: 80px 0 20px 0;
    max-width: 450px;
}

.gridButtons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    .wrapperTitle {
        margin: 0;
    }

    .gridButtons {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    .wrapper {
        flex-direction: column-reverse;
    }

    .featuredImage {
        margin: 5rem auto 0;
    }

    .wrappertTitle {
        text-align: center;
        margin: 0;
    }

    .albumDescription {
        max-width: 100%!important;
    }

    .spotifyIframe {
        left: 0;
        position: inherit;
    }
}

@media screen and (max-width: 1280px) {
    .albumDescription {
        max-width: 330px;
    }
}

