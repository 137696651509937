@import "../../_metronic/assets/sass/style";

//@include color-mode(light) {
//	body:not(.app-blank) {
//		background-image: url('../../../public/media/illustrations/gradient/fullhd2.jpg');
//	}
//}

//@include color-mode(dark) {
//	body:not(.app-blank) {
//		background-image: url('../../../public/media/illustrations/gradient/fullhd2.jpg');
//	}
//}

// Desktop mode
@include media-breakpoint-up(lg) {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		height: get($header-config, desktop, default, height);
		transition: left get($aside-config, transition-speed) ease;
		border-bottom: 0!important;

		// Header Logo
		.header-logo {
			padding: 0;
			display: flex;
			align-items: center;

			// Logos
			.logo-default {
				display: inline-block;
			}

			.logo-sticky {
				display: none;
			}
		}

		// Header menu
		.header-menu {
			display: flex;

			.menu {
				& > .menu-item {
					@include menu-link-here-state(
						$title-color: var(--#{$prefix}primary),
						$icon-color: var(--#{$prefix}primary),
						$bullet-color: var(--#{$prefix}primary),
						$arrow-color: var(--#{$prefix}primary),
						$bg-color: var(--#{$prefix}menu-link-bg-color-active),
						$all-links: false
					);
				}
			}
		}

		// Fixed header & sticky header modes
		.header-fixed[data-kt-sticky-header="on"] & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($header-config, desktop, fixed, z-index);
			background-color: var(--#{$prefix}header-desktop-fixed-bg-color);
			box-shadow: var(--#{$prefix}header-desktop-fixed-box-shadow);
			height: get($header-config, desktop, fixed, height);
			border-bottom: var(--#{$prefix}header-desktop-fixed-border);
			padding: 0;

			// Header Logo
			.header-logo {
				// Logos
				.logo-default {
					display: none;
				}

				.logo-sticky {
					display: inline-block;
				}
			}
		}

		// Fixed header & NON sticky header modes
		body:not([data-kt-sticky-header="on"]) & {
			// Header menu
			.header-menu {
				.menu {
					// Root links
					& > .menu-item {
				position: relative;
				margin-right: 2.3rem !important;
				padding-left: 0 !important;
				margin-left: 0 !important;

				// Customize menu link default state
				@include menu-link-default-state(
					$title-color: var(--#{$prefix}gray-200),
					$icon-color: null,
					$bullet-color: null,
					$arrow-color: null,
					$bg-color: null,
					$all-links: false
				);

				// Customize menu link hover state
				@include menu-link-hover-state(
					$title-color: var(--#{$prefix}gray-900),
					$icon-color: null,
					$bullet-color: null,
					$arrow-color: null,
					$bg-color: transparent,
					$all-links: false
				);

				// Customize menu link here state
				@include menu-link-here-state(
					$title-color: var(--#{$prefix}gray-900),
					$icon-color: null,
					$bullet-color: null,
					$arrow-color: null,
					$bg-color: transparent,
					$all-links: false
				);

				// Customize menu link show state
				@include menu-link-show-state(
					$title-color: var(--#{$prefix}gray-900),
					$icon-color: null,
					$bullet-color: null,
					$arrow-color: null,
					$bg-color: transparent,
					$all-links: false
				);

				// Customize menu link active state
				@include menu-link-active-state(
					$title-color: null,
					$icon-color: null,
					$bullet-color: null,
					$arrow-color: null,
					$bg-color: transparent,
					$all-links: false
				);

				> .menu-link {
					background-color: transparent !important;
					padding: 0 !important;
					margin-bottom: 8px;
					margin-left: 0 !important;
					font-weight: $font-weight-bold;
					font-size: 1.15rem;
				}

				// States
                &.here > .menu-link,
                & > .menu-link.active {
                    &:after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        content: " ";
                        background-color: var(--#{$prefix}gray-500);
						@include border-radius(20px);
                    }
                }
			}
				}
			}
		}
	}
}

.symbol {
    display: inline-block;;
    flex-shrink: 0;
    position: relative;
    border-radius: 2rem;

    // Label
    .symbol-label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: var(--#{$prefix}symbol-label-color);
        background-color: var(--#{$prefix}symbol-label-bg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-radius: 2rem;

        &:after {
            border-radius: 2rem;
        }
    }

    // Badge
    .symbol-badge {
        position: absolute;
        border-radius: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) !important;
    }

    // Image
    > img {
        width: 100%;
        flex-shrink: 0;
        display: inline-block;
        border-radius: 2rem;
    }

    // Square
    &.symbol-square {
        &,
        > img,
        .symbol-label {
            border-radius: 0 !important;
        }
    }

    // Circle
    &.symbol-circle {
        &,
        > img,
        .symbol-label {
            border-radius: 50%;

            &:after {
                border-radius: 50%;
            }
        }
    }

    // Sizes
    @include symbol-size(get($symbol-sizes, default));

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @each $name, $value in $symbol-sizes {
                @if ($name != 'default') {
                    &.symbol#{$infix}-#{$name} {
                        @include symbol-size($value);
                    }
                }
            }
        }
    }
}
