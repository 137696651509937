.socialWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    margin-top: 1rem
}

.socialArea {
    display: flex;
    gap: .5rem;
    align-items: center;
}
