/* Estilo para mensagem de erro */
.Toastify__toast--error {
    background-color: #131212;
    color: #ffffffab;
  }
  
  /* Estilo para o botão de fechar mensagem */
  .Toastify__close-button {
    color: #f5f5f5;
  }
  
  /* Estilo para a mensagem de erro no modo escuro */
  .Toastify__toast--error.Toastify__toast--dark {
    background-color: #a7172f;
    color: #fff;
  }