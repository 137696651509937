.wFull {
  width: 100%;
}

.resetInput {
  height: 45px;
  border: none;
}

.resetInput:focus {
  outline: none !important;
}

.customInput {
  border-radius: 1rem;
  padding: 1rem;
  display: block;
  width: 100%;
}

.label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: .5rem;
  color: #292929;
}

.label.required::after {
  content: '*';
  color: red;
  font-weight: bold;
  margin-left: 4px;
}

.customSelect {
  /* border-right: 12px solid transparent */
}

select {
  padding: 3px;
  margin: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #888;
  border: none;
  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}