.title {
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 700;
}

.subtitle {
    font-size: 4.5rem;
    line-height: 1;
    font-weight: bold;
}

.button {
    width: min-content; /* isso serve para evitar o click na div do botão */
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 1.25rem!important
    }

    .subtitle {
        font-size: 2.25rem!important
    }
}
