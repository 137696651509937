.colorPicker {
    animation: colorPicker 1s ease 0s 1 normal forwards;
    position: relative;
    width: fit-content;
}

@keyframes colorPicker {
	0% {
		opacity: 0;
		transform: rotateX(70deg);
		transform-origin: top;
	}

	100% {
		opacity: 1;
		transform: rotateX(0deg);
		transform-origin: top;
	}
}

.hideColorPicker {
    cursor: pointer;
    position: absolute;
    right: -16px;
    top: -16px;
    padding: 8px 12px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;

    -webkit-box-shadow: -42px 39px 61px -8px rgba(241,64,108,1);
-moz-box-shadow: -42px 39px 61px -8px rgba(241,64,108,1);
box-shadow: -42px 39px 61px -8px rgba(241,64,108,1);
}